
import { Component, Prop, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import CampaignInfo from '@/ui-components/CampaignInfo/CampaignInfo.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import ITextArea from '@/ui-components/ITextArea/ITextArea.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import { PendingCampaign } from '@/types/campaigns';
import { RejectCampaign, RejectCampaignMediaChange } from '@/store/modules/incoming-campaigns-module/types';
import namespaces from '@/store/namespaces';
import { Action } from 'vuex-class';
import { ShowSnackbar } from '@/types/snackbar';
import { Dialog } from '@/types/misc';

@Component({
  components: { Media, IButton, ITextArea, IDialog, CampaignInfo },
})
export default class MediaChangeRejectDialog extends Vue implements Dialog {
  @Prop({ type: Object, default: () => ({}) })
  public campaign!: PendingCampaign;

  public loading = false;
  public reason = '';

  @Action('rejectCampaignMediaChange', { namespace: namespaces.IncomingCampaignsModule })
  public rejectCampaignMediaChange!: RejectCampaignMediaChange;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  public get campaignLocations() {
    return this.campaign.locations.map(({ NAME }) => NAME).join(', ');
  }

  public async rejectMediaChange() {
    try {
      this.loading = true;
      const { campaignRequest } = this.campaign;
      await this.rejectCampaignMediaChange({ campaignRequest, rejectionReason: this.reason});
      const text = `${this.$t('you_rejected_the_content_update_for_the')} "${ this.campaign.name }" ${ this.$t('_at') } "${ this.campaignLocations }"`;
      this.showSnackbar({
        color: 'info',
        text,
      });
      this.close();
    } catch (error) {
      this.loading = false;
      this.showSnackbar({
        color: 'danger',
        text: this.$t('error_rejecting_media_change') as string,
      });
    }
  }

  public isOpen = false;

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
