
import { Component, Prop, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import CampaignInfo from '@/ui-components/CampaignInfo/CampaignInfo.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import { PendingCampaign } from '@/types/campaigns';
import { ApproveCampaign } from '@/store/modules/incoming-campaigns-module/types';
import namespaces from '@/store/namespaces';
import { Action } from 'vuex-class';
import { ShowSnackbar } from '@/types/snackbar';
import CampaignRejectDialog from './CampaignRejectDialog.vue';
import { Dialog } from '@/types/misc';
import CampaignMedia from '@/ui-components/CampaignMedia/CampaignMedia.vue';

@Component({
  components: { CampaignMedia, Media, IButton, IDialog, CampaignInfo, CampaignRejectDialog },
})
export default class CampaignReviewDialog extends Vue implements Dialog {
  @Prop({ type: Object, default: () => ({}) })
  public campaign!: PendingCampaign;

  public loading = false;

  @Action('approveCampaign', { namespace: namespaces.IncomingCampaignsModule })
  public approveCampaign!: ApproveCampaign;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  public get campaignLocations() {
    return this.campaign.locations.map(({ NAME }) => NAME).join(', ');
  }

  public get isAdSpaceHasMedia() {
    const [media] = this.campaign.mediaList;
    return media.path
  }

  public openCampaignRejectDialog() {
    const dialog = this.$refs.campaignRejectDialogRef as CampaignRejectDialog;
    dialog.open();
  }

  public async approveStoreCampaign() {
    try {
      this.loading = true;
      const { campaignRequest } = this.campaign;
      const text = `${this.$t('you_approved_new_campaign')} "${ this.campaign.name }" ${ this.$t('_at') } "${ this.campaignLocations }"`;
      await this.approveCampaign(this.campaign);
      this.showSnackbar({
        color: 'success',
        text,
      });
      this.close();
    } catch (error) {
      this.showSnackbar({
        color: 'danger',
        text: this.$t('error_approving_campaign') as string,
      });
    } finally {
      this.loading = false;
    }
  }

  public isOpen = false;

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
