
import { Component, Prop, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import CampaignMedia from '@/ui-components/CampaignMedia/CampaignMedia.vue';
import CampaignInfo from '@/ui-components/CampaignInfo/CampaignInfo.vue';
import MediaChangeRejectDialog from './MediaChangeRejectDialog.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import ITextArea from '@/ui-components/ITextArea/ITextArea.vue';
import IDialog from '@/ui-components/IDialog/IDialog.vue';
import { PendingCampaign } from '@/types/campaigns';
import { ApproveCampaignMediaChange } from '@/store/modules/incoming-campaigns-module/types';
import namespaces from '@/store/namespaces';
import { Action } from 'vuex-class';
import { ShowSnackbar } from '@/types/snackbar';
import { Dialog } from '@/types/misc';

@Component({
  components: {
    Media,
    IButton,
    ITextArea,
    IDialog,
    CampaignInfo,
    MediaChangeRejectDialog,
    CampaignMedia,
  },
})
export default class MediaChangeCampaignReviewDialog extends Vue
  implements Dialog {
  @Prop({ type: Object, default: () => ({}) })
  public campaign!: PendingCampaign;

  public loading = false;

  @Action('approveCampaignMediaChange', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public approveCampaignMediaChange!: ApproveCampaignMediaChange;

  @Action('showSnackbar', { namespace: namespaces.UiModule })
  public showSnackbar!: ShowSnackbar;

  public get campaignLocations() {
    return this.campaign.locations.map(({ NAME }) => NAME).join(', ');
  }

  public get oldMediaList() {
    return this.campaign.mediaList;
  }

  public get newMediaList() {
    return this.campaign.mediaList.map((media) =>
      media.changeRequestMedia ? media.changeRequestMedia : media
    );
  }

  public async approveMediaChange() {
    try {
      this.loading = true;
      const text = `${this.$t('you_approved_the_content_update_for_the')} "${
        this.campaign.name
      }" ${this.$t('_at')} "${this.campaignLocations}"`;
      await this.approveCampaignMediaChange(this.campaign);
      this.showSnackbar({
        color: 'success',
        text,
      });
      this.close();
    } catch (error) {
      this.showSnackbar({
        color: 'danger',
        text: this.$t('error_changing_campaign_media') as string,
      });
    } finally {
      this.loading = false;
    }
  }

  public openMediaChangeRejectDialog() {
    const dialog = this.$refs
      .mediaChangeRejectDialogRef as MediaChangeRejectDialog;
    dialog.open();
  }

  public isOpen = false;

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
