
import { Component, Prop, Vue } from 'vue-property-decorator';
import TabItem from '@/ui-components/Tabs/TabItem.vue';
import SkeletonLoading from './SkeletonLoading.vue';
import PendingCampaignCard from '@/ui-components/PendingCampaignCard/PendingCampaignCard.vue';
import NoCampaigns from '@/shared-components/NoCampaigns.vue';
import { CategorizedStorePendingCampaignsList } from '@/types/campaigns';
import { storeOwnerRouteNames } from '@/route-names/store-owner';

@Component({
  name: 'PendingCampaigns',
  components: {
    TabItem,
    NoCampaigns,
    SkeletonLoading,
    PendingCampaignCard,
  },
})
export default class PendingCampaigns extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: CategorizedStorePendingCampaignsList;

  @Prop({ type: Boolean })
  public loading!: boolean;

  public get hasCampaigns() {
    const [
      { campaigns: pendingApprovalCampaigns },
      { campaigns: changeMediaRequestCampaigns },
    ] = this.campaigns;
    return (
      pendingApprovalCampaigns.length ||
      changeMediaRequestCampaigns.length
    );
  }

  public campaignCreationRoute = storeOwnerRouteNames.MANAGE_MY_ADS;
}
