
import { Component, Prop, Vue } from 'vue-property-decorator';
import TabItem from '@/ui-components/Tabs/TabItem.vue';
import SkeletonLoading from './SkeletonLoading.vue';
import NoCampaigns from '@/shared-components/NoCampaigns.vue';
import UpcomingCampaignsTable from './UpcomingCampaignsTable.vue';
import { UpcomingCampaign } from '@/types/campaigns';

@Component({
  name: 'UpcomingCampaigns',
  components: {
    TabItem,
    NoCampaigns,
    SkeletonLoading,
    UpcomingCampaignsTable,
  },
})
export default class UpcomingCampaigns extends Vue {
  @Prop({ type: Array, default: () => [] })
  public campaigns!: UpcomingCampaign[];

  @Prop({ type: Boolean })
  public loading!: boolean;

  @Prop({ type: String })
  public activeTab!: string;
}
