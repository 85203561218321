
import { Component, Vue, Watch } from 'vue-property-decorator';
import PageTitle from '@/ui-components/PageTitle/PageTitle.vue';
import IButton from '@/ui-components/IButton/IButton.vue';
import IncomingCampaignsTabs from './components/IncomingCampaignsTabs.vue';
import { ResetAllCampaigns } from '@/store/modules/incoming-campaigns-module/types';
import namespaces from '@/store/namespaces';
import { Action } from 'vuex-class';
import { Route } from 'vue-router';
import { storeOwnerRouteNames } from '@/route-names/store-owner';

@Component({
  name: 'IncomingCampaigns',
  components: { IncomingCampaignsTabs, PageTitle, IButton },
})
export default class IncomingCampaigns extends Vue {
  @Action('resetAllCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public resetAllCampaigns!: ResetAllCampaigns;

  @Watch('$route')
  public routeChange(_: Route, to: Route) {
    if (
      to.name !== storeOwnerRouteNames.INCOMING_CAMPAIGN_VIEW.name &&
      to.name !== storeOwnerRouteNames.INCOMING_CAMPAIGNS.name
    ) {
      this.resetAllCampaigns();
    }
  }
  navigateToCreateStoreOwnerCampaign(){
    this.$router.push({
      ...storeOwnerRouteNames.CREATE_STORE_OWNER_CAMPAIGN,
    });
  }
  public created() {
    window.addEventListener('beforeunload', () => {
      this.resetAllCampaigns();
    })
  }
}
