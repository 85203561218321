
import { Component, Vue, Watch } from 'vue-property-decorator';
import Tabs from '@/ui-components/Tabs/Tabs.vue';
import Tab from '@/ui-components/Tabs/Tab.vue';
import TabsItems from '@/ui-components/Tabs/TabsItems.vue';
import TabItem from '@/ui-components/Tabs/TabItem.vue';
import { incomingCampaignsCategories } from '@/statics/incoming-campaigns';
import PendingCampaigns from './PendingCampaigns/PendingCampaigns.vue';
import RunningCampaigns from './RunningCampaigns/RunningCampaigns.vue';
import CompletedCampaigns from './CompletedCampaigns/CompletedCampaigns.vue';
import UpcomingCampaigns from './UpcomingCampaigns/UpcomingCampaigns.vue';
import { Action, Getter } from 'vuex-class';
import namespaces from '@/store/namespaces';
import {
  LoadCompletedCampaigns,
  LoadPendingCampaigns,
  LoadRunningCampaigns,
  LoadStoreScreenClusters,
  LoadUpcomingCampaigns,
  setActiveTab,
} from '@/store/modules/incoming-campaigns-module/types';
import {
  Campaign,
  CategorizedStorePendingCampaignsList,
} from '@/types/campaigns';
import { ScreenCluster } from '@/types/screen-cluster';

@Component({
  name: 'IncomingCampaignsTabs',
  components: {
    Tabs,
    Tab,
    TabsItems,
    TabItem,
    PendingCampaigns,
    RunningCampaigns,
    CompletedCampaigns,
    UpcomingCampaigns,
  },
})
export default class IncomingCampaignsTabs extends Vue {
  @Action('loadPendingCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadPendingCampaigns!: LoadPendingCampaigns;

  @Action('loadCompletedCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadCompletedCampaigns!: LoadCompletedCampaigns;

  @Action('loadRunningCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadRunningCampaigns!: LoadRunningCampaigns;

  @Action('loadUpcomingCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public loadUpcomingCampaigns!: LoadUpcomingCampaigns;

  @Action('loadStoreScreenClusters', {
    namespace: namespaces.StoreModule,
  })
  public loadStoreScreenClusters!: LoadStoreScreenClusters;

  @Action('setActiveTab', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public setActiveTab!: setActiveTab;

  @Getter('pendingCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public pendingCampaigns!: CategorizedStorePendingCampaignsList;

  @Getter('completedCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public completedCampaigns!: Campaign[];

  @Getter('runningCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public runningCampaigns!: Campaign[];

  @Getter('upcomingCampaigns', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public upcomingCampaigns!: Campaign[];

  @Getter('screenClusters', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public screenClusters!: ScreenCluster[];

  @Getter('activeTab', {
    namespace: namespaces.IncomingCampaignsModule,
  })
  public storeActiveTab!: string;

  public activeTab: string | null = this.storeActiveTab || null;
  public loading = false;

  public categories = incomingCampaignsCategories;

  public tabsCategoriesActionsMap: { [key: string]: () => Promise<void> } = {
    pending_campaigns: this.loadPendingCampaigns,
    completed_campaigns: this.loadCompletedCampaigns,
    running_campaigns: this.loadRunningCampaigns,
    upcoming_campaigns: this.loadUpcomingCampaigns,
  };

  public async tabChanged(tabName: string) {
    this.setActiveTab(tabName)
    this.setLastVisitedTab();
    if (this.activeTab !== tabName) {
      this.$router.push({
        hash: tabName,
      });
    }

  }

  public async reloadData() {
    if (this.activeTab) {
      const action = this.tabsCategoriesActionsMap[this.activeTab];

      this.loading = true;
      await action();
      this.loading = false;
    }
  }

  public setLastVisitedTab() {
    this.activeTab = this.$route.hash.replace('#', '');
  }

  public async initData() {
    await this.loadStoreScreenClusters();
    this.setLastVisitedTab();
    await this.reloadData();
  }

  @Watch('$route')
  public routeChange() {
    this.initData();
  }

  public async created() {
    const isPageOpened = window.sessionStorage.getItem('pageOpened')
    if (!isPageOpened) {
      this.initData();
    } else {
      this.activeTab = this.storeActiveTab;
    }
  }
}
